import React from 'react'
import background from '../assets/b2.png'
import hero from '../assets/cslogopurpletwolines.png'

function HeaderCS() {
  const scrollToProjects = () => {
    const projectsSection = document.getElementById('projects-section');
    if (projectsSection) {
      projectsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="w-screen h-screen text-white bg-gray-800 relative overflow-hidden bg-no-repeat bg-cover bg-center 
    "  style={{
      background: `url(${background})`,
    }}>
      <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col pl-10 pr-10">
        <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center " alt="hero" src={hero} />
        <div class="text-center lg:w-5/12 w-full">
          
          <p className="text-2xl mb-8 text-gray-800 font-bold">
          Turn your designs into production-ready 
          </p>
          <p className="text-2xl mb-8 text-gray-800">
          We revolutionize businesses with advanced digital solutions that meet today's needs and unlock tomorrow's opportunities.
          </p>
          <div className="flex justify-center mx-auto">
            <button
             
             onClick={scrollToProjects}
              className="hover:underline bg-white text-gray-800 font-bold rounded-full  py-4 px-8">
              View Projects
            </button>
            <button
            onClick={() => window.open("https://calendly.com/codingsolved/coding-solved-appointment")}
              className="ml-4 hover:underline bg-white text-gray-800 font-bold rounded-full  py-4 px-8">
              Schedule a Call
            </button>
          </div>
        </div>
      </div>
    </div >
  );
}


export default HeaderCS