import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useFormik } from "formik";
import * as yup from "yup";
import codingsSolvedLogo from "../assets/cslogopurpletwolines.png";
import background from "../assets/b2.png";  

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  message: yup.string().required("Message is required"),
});

const projects = [
  {
    id: "greenpoint_qc",
    name: "GreenPoint QC",
  },
  {
    id: "casa_cristina",
    name: "Casa Cristina",
  },
  {
    id: "habitat",
    name: "Habitat",
  },
  {
    id: "lowt",
    name: "LowT",
  },
  {
    id: "nsbjj",
    name: "NSBJJ",
  },
  {
    id: "softalmology",
    name: "Softalmology",
  },
  {
    id: "ldg",
    name: "LDG",
  },
];

const questions = [
  {
    id: "1",
    question: "How can create a new account?",
  },

  {
    id: "2",
    question: "How can I delete my account?",
  },
  // how can export my data
  {
    id: "3",
    question: "How can I export my data?",
  },
];

const ContactForm = () => {
  const { projectId, questionId } = useParams();
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState("");

  useEffect(() => {
    if (projectId) setSelectedProject(projectId);
    if (questionId) setSelectedQuestion(questionId);
  }, [projectId, questionId]);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",

    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        try {
            
            const project = projects.find((p) => p.id === selectedProject)?.name;
            const question = questions.find((q) => q.id === selectedQuestion)?.question;
          
            const mailtoLink = `mailto:admin@codingsolved.com?subject=Contacto desde ${project}&body=Name: ${values.name}%0AEmail: ${values.email}%0AMessage: ${values.message}%0AProject: ${project}%0AQuestion: ${question}`;
            window.location.href = mailtoLink;
        } catch (error) {
            alert("An error occurred while trying to send the email ${error}");
        }
      },
  });

  return (
    <div className="flex items-center justify-center h-screen bg-cover bg-center bg-no-repeat bg-fixed" style={{backgroundImage: `url(${background})`}}>
      <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-md">
        <img
          src={codingsSolvedLogo}
          alt="Codings Solved Logo"
          className="w-auto h-32"
        />
        {/* coding solved  text*/}
        <br></br>
        <br></br>
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-black text-center leading-tight mb-4">
          We're ready to{" "}
          <span className="text-purple-600">get you started</span>
        </h1>
        <p className="text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 text-center mb-8">
          Let's chat about how we can help you transform your production
          monitoring.
        </p>
        <form className="flex flex-col" onSubmit={formik.handleSubmit}>
          
          {/* Dropdown para Project */}
            <label className="mt-6 text-sm font-medium">Select Project</label>
          <select
            className="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-sky-500 w-96"
            value={selectedProject}
            onChange={(e) => setSelectedProject(e.target.value)}
          >
            {projects.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>

          {/* Dropdown para Question */}
            <label className="mt-6 text-sm font-medium">Select Question</label>
          <select
            className="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-sky-500 w-96"
            value={selectedQuestion}
            onChange={(e) => setSelectedQuestion(e.target.value)}
          >
            {questions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.question}
              </option>
            ))}
          </select>
          <input
            className="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-sky-500 w-96"
            placeholder="Your Email Address"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.errors.email && (
            <div className="text-red-500 text-xs italic">
              {formik.errors.email}
            </div>
          )}
          <input
            className="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-sky-500 w-96"
            placeholder="Full Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.errors.name && (
            <div className="text-red-500 text-xs italic">
              {formik.errors.name}
            </div>
          )}
          <textarea
            className="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-sky-500 w-96 h-24"
            placeholder="Your Message Here"
            name="message"
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          {formik.errors.message && (
            <div className="text-red-500 text-xs italic">
              {formik.errors.message}
            </div>
          )}
          {/* Dropdown para Project */}

          <button
            className="mt-8 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-14"
            type="submit"

          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
