import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function OfferingSection() {
    // selected offering
    const [selectedOffering, setSelectedOffering] = React.useState(0);
    // selected carrousel or tasb
    const [selectedCarrousel, setSelectedCarrousel] = React.useState(0);



    // Offerings
    // Ofertas
    const offerings = [
        {
            title: 'Mobile App Development',
            content: {
                description: 'At CodingSolved, we specialize in creating cutting-edge mobile applications that cater to both Android and iOS platforms. Our team of experienced developers and AI experts collaborate to deliver solutions that are not only user-friendly but also leverage the power of artificial intelligence and machine learning. From concept to deployment, we ensure seamless functionality, captivating user interfaces, and integration of intelligent features that set your app apart in today\'s competitive market.',
                dots: [
                    {
                        title: 'Android App Development',
                        content: {
                            description: 'Our Android app development services at CodingSolved are designed to help you reach a wider audience and grow your business. We create custom Android applications that are tailored to your specific needs and deliver exceptional user experiences. Our team of experts leverages the latest technologies to build robust and scalable solutions that are optimized for performance and security. From concept to deployment, we ensure seamless functionality, captivating user interfaces, and integration of intelligent features that set your app apart in today\'s competitive market.',
                        }
                    },
                    {
                        title: 'iOS App Development',
                        content: {
                            description: 'Our iOS app development services at CodingSolved are designed to help you reach a wider audience and grow your business. We create custom iOS applications that are tailored to your specific needs and deliver exceptional user experiences. Our team of experts leverages the latest technologies to build robust and scalable solutions that are optimized for performance and security. From concept to deployment, we ensure seamless functionality, captivating user interfaces, and integration of intelligent features that set your app apart in today\'s competitive market.',
                        }
                    },
                    {
                        title: 'User-Centric Design',
                        content: {
                            description: 'At CodingSolved, we prioritize user-centric design in our mobile app development process. We understand that a well-designed and intuitive user interface is crucial for engaging users and providing a positive experience. Our design experts work closely with our developers to ensure that every aspect of the app, from navigation to interactions, is optimized for user satisfaction.',
                        }
                    },
                    {
                        title: 'Cross-Platform Compatibility',
                        content: {
                            description: 'We excel in developing cross-platform mobile applications that can seamlessly run on both Android and iOS devices. By leveraging frameworks and tools like React Native, we minimize development time and costs while delivering apps that maintain a consistent and high-quality user experience across different platforms.',
                        }
                    },
                    {
                        title: 'Continuous Support and Updates',
                        content: {
                            description: 'Our commitment doesn\'t end with the app launch. We provide ongoing support and maintenance services to ensure your app remains up-to-date, secure, and optimized for evolving user needs and technological advancements. We analyze user feedback, monitor app performance, and implement regular updates to keep your app competitive in the ever-changing app landscape.',
                        }
                    }
                ]
            }
        },
        {
            title: 'Advanced Web Development',
            content: {
                description: 'Our advanced web development services at CodingSolved encompass a wide range of solutions tailored to your specific needs. Our team combines creativity and technical expertise to deliver web applications that provide exceptional user experiences. By harnessing the latest trends in web technology and cloud computing, we create platforms that are not only visually appealing but also robust and efficient. From e-commerce websites to complex web applications, we empower your online presence with the tools for success.',
                dots: [
                    {
                        title: 'Custom UI/UX Design',
                        content: {
                            description: 'We prioritize user-centered design in our web development process. Our team of skilled designers crafts custom user interfaces that enhance user engagement and drive conversions. By understanding your target audience and business goals, we create visually appealing and intuitive interfaces that resonate with your users.',
                        }
                    },
                    {
                        title: 'Scalable Architecture',
                        content: {
                            description: 'Our web applications are built on a scalable architecture that grows with your business. Whether you expect a surge in traffic or plan to add new features, our solutions are designed to handle increased load and functionality. We ensure your website or application remains responsive, efficient, and capable of accommodating future needs.',
                        }
                    },
                    {
                        title: 'E-Commerce Expertise',
                        content: {
                            description: 'Our expertise extends to crafting powerful e-commerce solutions. From online stores to payment gateways and inventory management, we create seamless shopping experiences that enhance customer satisfaction. We prioritize security and user-friendly navigation to help you succeed in the competitive e-commerce landscape.',
                        }
                    },
                    {
                        title: 'Responsive and Cross-Browser Compatibility',
                        content: {
                            description: 'We ensure your web applications work flawlessly across various devices and browsers. Our responsive designs adapt to different screen sizes, providing consistent user experiences. By conducting thorough testing, we guarantee compatibility with popular browsers, ensuring that your website reaches a wide audience without technical hitches.',
                        }
                    },
                    {
                        title: 'Performance Optimization',
                        content: {
                            description: 'Speed and performance are paramount in delivering a satisfying user experience. Our web development process includes rigorous optimization to ensure fast loading times, smooth navigation, and minimal downtime. We employ techniques such as code optimization, caching, and content delivery networks (CDNs) to maximize your website\'s performance.',
                        }
                    },
                    {
                        title: 'Data Security and Privacy',
                        content: {
                            description: 'We prioritize the security and privacy of your data. Our web development practices include implementing robust security measures to protect sensitive user information and prevent unauthorized access. From encryption to secure authentication, we ensure that your web applications meet industry standards and compliance regulations.',
                        }
                    }
                ]
            }
        },

        {
            title: 'Smart Medical Software',
            content: {
                description: 'CodingSolved stands at the forefront of intelligent medical software development. Our expertise in this niche area enables us to create solutions that drive innovation in healthcare. By integrating IoT, data analytics, and AI, we enhance medical practices with streamlined processes, accurate diagnostics, and improved patient care. From telemedicine platforms to data-driven treatment recommendations, our smart medical software solutions empower healthcare professionals and institutions to deliver top-notch care in an increasingly digital world.',
                dots: [
                    {
                        title: 'Telemedicine Solutions',
                        content: {
                            description: 'Our telemedicine solutions bridge the gap between healthcare providers and patients, enabling remote consultations and care. We develop user-friendly platforms that facilitate secure video calls, appointment scheduling, prescription management, and remote monitoring, ensuring continuity of care while improving patient access and convenience.',
                        }
                    },
                    {
                        title: 'Predictive Analytics for Health Insights',
                        content: {
                            description: 'We leverage the power of predictive analytics to provide valuable health insights. By analyzing patient data and historical trends, our smart medical software can help healthcare professionals anticipate potential health issues, enabling early intervention and personalized treatment plans.',
                        }
                    },
                    {
                        title: 'IoT-Enabled Medical Devices',
                        content: {
                            description: 'Our IoT-enabled medical devices enhance patient monitoring and care. We develop devices that collect real-time health data, such as vital signs and medication adherence, to provide accurate information to healthcare providers. This data-driven approach leads to more informed decisions and improved patient outcomes.',
                        }
                    },
                    {
                        title: 'Data-Driven Treatment Recommendations',
                        content: {
                            description: 'Our smart medical software analyzes patient data to generate data-driven treatment recommendations. By considering individual patient characteristics and medical history, we assist healthcare providers in making informed decisions about treatments, medications, and interventions.',
                        }
                    },
                    {
                        title: 'Secure Data Management',
                        content: {
                            description: 'We prioritize the security and privacy of patient data. Our smart medical software solutions implement robust data encryption, secure authentication, and compliance with healthcare regulations. We ensure that sensitive patient information remains confidential and protected against unauthorized access.',
                        }
                    },
                    {
                        title: 'Interoperability and Integration',
                        content: {
                            description: 'We enable seamless integration of our smart medical software with existing healthcare systems and electronic health records (EHRs). By ensuring interoperability, we facilitate the exchange of patient data and enable healthcare professionals to access comprehensive information for informed decision-making.',
                        }
                    }
                ]
            }
        },
        {
            title: 'Artificial Intelligence and IoT',
            content: {
                description: 'Our Artificial Intelligence and IoT services transform the way businesses operate. At CodingSolved, we design and implement AI-powered systems and IoT solutions that optimize processes, automate tasks, and extract valuable insights from data streams. Our AI solutions range from predictive analytics to natural language processing, while our IoT implementations connect devices, collect data, and enable real-time monitoring. By combining these technologies, we empower businesses to make data-driven decisions, enhance efficiency, and unlock new avenues of growth.',
                dots: [
                    {
                        title: 'Predictive Analytics',
                        content: {
                            description: 'Our AI solutions utilize predictive analytics to forecast future trends and outcomes based on historical data. This empowers businesses to make proactive decisions, optimize resource allocation, and anticipate customer preferences for better strategic planning.',
                        }
                    },
                    {
                        title: 'Natural Language Processing (NLP)',
                        content: {
                            description: 'We implement NLP technology to enable machines to understand, interpret, and generate human language. This capability enhances customer interactions through chatbots, virtual assistants, and sentiment analysis, improving communication and driving customer satisfaction.',
                        }
                    },
                    {
                        title: 'IoT Data Insights',
                        content: {
                            description: 'We harness IoT data to extract meaningful insights. By collecting and analyzing data from connected devices, we provide businesses with valuable information that can guide decision-making, optimize operations, and identify opportunities for process improvement.',
                        }
                    },
                    {
                        title: 'Automation and Process Optimization',
                        content: {
                            description: 'Our AI-powered systems automate routine tasks and streamline complex processes. By implementing machine learning algorithms, businesses can achieve greater efficiency, reduce human error, and allocate resources more effectively.',
                        }
                    },
                    {
                        title: 'Real-time Monitoring and Alerts',
                        content: {
                            description: 'We create IoT solutions that enable real-time monitoring of equipment, assets, and environmental conditions. Businesses receive instant alerts and notifications, allowing them to respond promptly to changes, prevent issues, and ensure optimal performance.',
                        }
                    },
                    {
                        title: 'Data-Driven Decision Making',
                        content: {
                            description: 'By combining AI and IoT, we provide businesses with the tools to make data-driven decisions. From supply chain management to customer behavior analysis, our solutions empower organizations to base strategies on accurate insights, driving growth and innovation.',
                        }
                    }
                ]
            }
        },
        {
            title: 'Computer Vision',
            content: {
                description: 'Leveraging the power of Computer Vision, CodingSolved crafts applications that enable machines to understand and interpret the visual world. Our computer vision solutions find applications across diverse industries, from manufacturing and retail to healthcare and security. We create systems that analyze images and videos, enabling tasks such as object recognition, anomaly detection, and facial recognition. By harnessing the potential of Computer Vision, we empower businesses with enhanced automation, quality control, and insights from visual data.',
                dots: [
                    {
                        title: 'Object Recognition',
                        content: {
                            description: 'Our Computer Vision solutions excel at recognizing objects within images and videos. Whether it\'s identifying products on a production line or detecting obstacles for autonomous vehicles, our technology enhances efficiency and safety across various industries.',
                        }
                    },
                    {
                        title: 'Anomaly Detection',
                        content: {
                            description: 'We implement anomaly detection algorithms to identify unusual patterns or behaviors in visual data. This is invaluable for quality control in manufacturing, cybersecurity, and predictive maintenance, as it enables businesses to detect deviations from the norm.',
                        }
                    },
                    {
                        title: 'Facial Recognition',
                        content: {
                            description: 'Our Facial Recognition technology enables secure authentication and identification. From access control in secure facilities to personalized customer experiences, we create systems that analyze facial features for accurate identification and authentication.',
                        }
                    },
                    {
                        title: 'Automation in Manufacturing',
                        content: {
                            description: 'Computer Vision revolutionizes manufacturing by automating tasks such as defect detection and quality assessment. Our solutions ensure consistent product quality, reduce defects, and optimize production processes, leading to increased efficiency and cost savings.',
                        }
                    },
                    {
                        title: 'Retail Analytics',
                        content: {
                            description: 'In the retail sector, Computer Vision helps businesses understand customer behavior and optimize store layouts. We develop systems that analyze foot traffic, customer demographics, and product interactions, providing insights to enhance customer experiences and drive sales.',
                        }
                    },
                    {
                        title: 'Healthcare Imaging',
                        content: {
                            description: 'Computer Vision plays a vital role in healthcare imaging, assisting in tasks like medical image analysis and diagnostics. Our solutions enhance radiology by automating the detection of abnormalities, improving accuracy, and expediting patient care.',
                        }
                    }
                ]
            }
        },
        {
            title: 'Cloud-Based Solutions',
            content: {
                description: 'Harness the power of cloud computing with our tailored cloud-based solutions. From scalable infrastructure to data storage and processing, we ensure your applications run seamlessly and securely in the cloud environment. Our experts optimize resources and deploy robust strategies, enabling you to focus on innovation while we handle the technical complexities.',
                dots: [
                    {
                        title: 'Scalable Infrastructure',
                        content: {
                            description: 'Our cloud-based solutions provide scalable infrastructure that grows with your business needs. Whether you experience sudden surges in traffic or plan for expansion, our solutions can dynamically adjust resources to maintain optimal performance and minimize downtime.',
                        }
                    },
                    {
                        title: 'Data Storage and Management',
                        content: {
                            description: 'We offer efficient data storage and management solutions in the cloud. From databases to data lakes, we help you store, organize, and access your data securely while ensuring high availability and reliability for mission-critical applications.',
                        }
                    },
                    {
                        title: 'Serverless Architecture',
                        content: {
                            description: 'Our cloud solutions leverage serverless architecture to eliminate the need for managing infrastructure. By adopting serverless computing, you can focus on writing code and developing features while the cloud provider handles the backend infrastructure and scaling.',
                        }
                    },
                    {
                        title: 'Cost Optimization',
                        content: {
                            description: 'We optimize your cloud resources to minimize costs without compromising performance. Through efficient resource allocation, automation, and right-sizing, we ensure that you pay only for what you use, helping you achieve cost savings and operational efficiency.',
                        }
                    },
                    {
                        title: 'Security and Compliance',
                        content: {
                            description: 'Security is paramount in the cloud. We implement robust security measures to protect your data and applications, ensuring compliance with industry regulations and standards. From encryption to access controls, your cloud environment remains secure.',
                        }
                    },
                    {
                        title: 'Migration and Integration',
                        content: {
                            description: 'If you\'re transitioning to the cloud, we provide seamless migration and integration services. We help you move existing applications and data to the cloud while maintaining functionality and minimizing disruption, ensuring a smooth transition to the cloud environment.',
                        }
                    }
                ]
            }
        },

        {
            title: 'IoT Integration for Manufacturing',
            content: {
                description: 'Revolutionize your manufacturing processes with our IoT integration services. We connect machinery and equipment, collecting real-time data to optimize operations, monitor equipment health, and predict maintenance needs. By leveraging IoT technology, we help you achieve greater efficiency, reduced downtime, and improved productivity on the factory floor.',
                dots: [
                    {
                        title: 'Real-Time Equipment Monitoring',
                        content: {
                            description: 'Our IoT integration allows real-time monitoring of machinery and equipment performance. By collecting data on variables such as temperature, vibration, and usage, we provide insights that enable proactive maintenance and prevent unexpected downtime.',
                        }
                    },
                    {
                        title: 'Predictive Maintenance',
                        content: {
                            description: 'We implement predictive maintenance solutions that leverage data analytics and machine learning. By analyzing historical equipment data, we can predict when maintenance is required, allowing you to schedule maintenance activities at optimal times and avoid costly unplanned breakdowns.',
                        }
                    },
                    {
                        title: 'Process Optimization',
                        content: {
                            description: 'Our IoT solutions optimize manufacturing processes by analyzing data and identifying bottlenecks, inefficiencies, and areas for improvement. By optimizing workflows and resource allocation, we help you achieve greater operational efficiency and cost savings.',
                        }
                    },
                    {
                        title: 'Remote Equipment Management',
                        content: {
                            description: 'Our IoT integration enables remote management and control of manufacturing equipment. You can monitor and control machinery from anywhere, enabling remote troubleshooting, adjustments, and even remote operation, improving operational flexibility.',
                        }
                    },
                    {
                        title: 'Data-Driven Insights',
                        content: {
                            description: 'We transform IoT-generated data into actionable insights. By visualizing and analyzing data trends, you can make informed decisions to optimize processes, increase equipment reliability, and enhance overall manufacturing performance.',
                        }
                    },
                    {
                        title: 'Integration with Existing Systems',
                        content: {
                            description: 'We seamlessly integrate IoT solutions with your existing manufacturing systems, such as Enterprise Resource Planning (ERP) and Manufacturing Execution Systems (MES). This ensures that your IoT data is accessible and utilized alongside your other critical business processes.',
                        }
                    }
                ]
            }
        },

        {
            title: 'E-Commerce Solutions',
            content: {
                description: 'Enhance your online business with our comprehensive e-commerce solutions. We create user-friendly and visually appealing e-commerce platforms that offer seamless shopping experiences. Our expertise includes payment gateway integration, inventory management, and personalized recommendation systems, ensuring your e-commerce venture stands out in the competitive digital landscape.',
                dots: [
                    {
                        title: 'Customized Storefront Design',
                        content: {
                            description: 'We design visually appealing and user-friendly storefronts that reflect your brand identity and resonate with your target audience. Our e-commerce solutions enhance user engagement, navigation, and ultimately drive higher conversion rates.',
                        }
                    },
                    {
                        title: 'Payment Gateway Integration',
                        content: {
                            description: 'We integrate secure payment gateways that provide smooth and secure payment processing for your customers. Whether it\'s credit card payments, digital wallets, or other payment methods, we ensure a hassle-free checkout experience.',
                        }
                    },
                    {
                        title: 'Inventory Management',
                        content: {
                            description: 'Our e-commerce solutions include robust inventory management systems. We help you keep track of stock levels, manage product variants, and streamline order fulfillment, ensuring accurate product availability and timely deliveries.',
                        }
                    },
                    {
                        title: 'Personalized Shopping Experience',
                        content: {
                            description: 'We implement personalized recommendation systems that enhance the shopping experience. By analyzing user behavior and preferences, we offer product suggestions that increase cross-selling and upselling opportunities, driving higher revenue.',
                        }
                    },
                    {
                        title: 'Mobile Responsiveness',
                        content: {
                            description: 'We prioritize mobile responsiveness to ensure seamless shopping experiences across devices. With more customers shopping on mobile devices, our e-commerce solutions adapt to different screen sizes and provide consistent user experiences.',
                        }
                    },
                    {
                        title: 'Analytics and Insights',
                        content: {
                            description: 'Our e-commerce solutions provide valuable analytics and insights. We track user behavior, sales trends, and website performance metrics to help you make data-driven decisions, refine strategies, and optimize your online store for better results.',
                        }
                    }
                ]
            }
        },

        // Puedes agregar más ofertas según necesites
    ];


    // on resize window 
    window.addEventListener('resize', () => {
        console.log(window.innerWidth);
        if (window.innerWidth < 768) {
            setSelectedCarrousel(1);
        } else {
            setSelectedCarrousel(0);
        }
    });

    React.useEffect(() => {
        if (window.innerWidth < 768) {
            setSelectedCarrousel(1);
        } else {
            setSelectedCarrousel(0);
        }
    }, []);






    return (
        <section className="py-10 bg-gray-100">
            <div className="container mx-auto">
                <h2 className="text-4xl font-bold mb-6 text-left text-slate-800">Explore our offering</h2>
                <div className="hidden md:flex">
                    <div className="w-1/4 pr-6 md:w-2/4 bg-slate-200 p-10">
                        <ul className="space-y-4 align-top text-left p-5">
                            {offerings.map((offering, index) => (
                                <li
                                    key={index}
                                    className={`cursor-pointer font-semibold text-xl transition duration-300 ease-in-out ${selectedOffering === index ? "bg-blue-500 text-white p-2" : "hover:text-white p-2"
                                        }`}
                                    onClick={() => setSelectedOffering(index)}
                                >
                                    {offering.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="w-3/4 bg-white shadow-md p-20">
                        <div className="space-y-4">
                            <h3 className="text-2xl font-bold text-left text-slate-800">{offerings[selectedOffering].title}</h3>
                            <p className="text-left text-slate-600 leading-1 text-xl">{offerings[selectedOffering].content.description}</p>
                        </div>
                        <div className="dots text-left mt-5 grid grid-rows-3 md:grid-cols-2 gap-4">
                            {offerings[selectedOffering].content.dots && offerings[selectedOffering].content.dots.map((dot, dotIndex) => (
                                <div key={dotIndex} className="mb-5">
                                    <div className="text-slate-800 font-normal text-xl mb-1">
                                        <span className="mr-2">&#9632;</span>
                                        {dot.title}
                                    </div>
                                    {/* <p className="text-slate-800">{dot.content.description}</p> */}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className={`${selectedCarrousel === 0 ? "hidden" : "block"} p-10`}>
                    <Carousel
                        showArrows={true}
                        showStatus={true}
                        showIndicators={true}
                        showThumbs={true}
                        swipeable={true} // Enable swipe gestures for mobile
                        emulateTouch={true} // Enable touch emulation for non-touch devices
                        dynamicHeight={false} // Set to true if you want dynamic slide heights
                        infiniteLoop={true} // Set to false to disable infinite loop behaviour
                        autoPlay={true} // Enable autoplay
                        interval={5000} // Delay between each autoPlay scroll (in milliseconds)

                    >
                        {offerings.map((offering, index) => (
                            <div key={index} className="bg-white shadow-md p-6">
                                <h3 className="text-2xl font-bold text-left text-slate-800">{offering.title}</h3>
                                <p className="text-left text-slate-800">{offering.content.description}</p>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </section>
    );
}


export default OfferingSection;